// Used for the 'vue-ellipse-progress' package as the color styling
const gradientBlueLime = {
  radial: false,
  colors: [
    {
      color: "#54beff",
      offset: "0",
      opacity: "1",
    },
    {
      color: "lime",
      offset: "180",
      opacity: "0.35",
    },
  ],
}

const PHONE_NUMBER = "+48 798 153 115"

export default { gradientBlueLime, PHONE_NUMBER }

export const TEST_API_URL = "https://maturait-testing.azurewebsites.net"
