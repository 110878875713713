import { defineStore } from "pinia"
import { AuthCallBack, StorageType } from "@/types/auth"
import { getStorageWrapper, setStorageWrapper } from "@/stores/utils"
import { AccountService, UserRegisterDataRequest, OpenAPI } from "@/api/generated"
import { getResponseBody } from "@/queries/utils"
import { pubsubSubscriber } from "@/communication/pubsub"

export type RootState = {
  authenticated: boolean
  authenticating: boolean
  error: boolean
  firstLogin: boolean
  callback: AuthCallBack
  authenticatingPromise?: Promise<boolean>
}

const TOKEN_STORAGE_KEY = "NOTE"
const PLATFORM_VERSION = "4"
const PLATFORM_VERSION_KEY = "platformVersion"

function createAuthStore(callback: AuthCallBack) {
  return defineStore("auth", {
    state: (): RootState => {
      return {
        authenticated: false,
        authenticating: false,
        error: false,
        firstLogin: true,
        callback: callback,
        authenticatingPromise: undefined,
      }
    },
    getters: {
      isAuthenticated: (state: RootState): boolean => {
        return state.authenticated
      },
      isFirstLogin: (state: RootState): boolean => {
        return state.firstLogin
      },
      isError: (state: RootState): boolean => {
        return state.error
      },
      isAuthenticating: (state: RootState): boolean => {
        return state.authenticating
      },
    },
    actions: {
      async initialize() {
        if (this.authenticatingPromise !== undefined) {
          return this.authenticatingPromise
        }
        this.setBase()
        this.clearOldVersionStorage()
        let token = localStorage.getItem(TOKEN_STORAGE_KEY)
        if (token) {
          setStorageWrapper(localStorage)
        } else {
          token = sessionStorage.getItem(TOKEN_STORAGE_KEY)
        }
        let result = false
        if (token) {
          this.setToken(token, StorageType.NONE)
          this.initializePubSub()
          this.LoginSuccess()
          result = true
        }

        this.authenticatingPromise = new Promise((resolve) => resolve(result))
        return this.authenticatingPromise
      },

      setToken(token: string, storage: StorageType): void {
        OpenAPI.TOKEN = token
        if (storage === StorageType.LOCAL) {
          localStorage.setItem(TOKEN_STORAGE_KEY, token)
          setStorageWrapper(localStorage)
        } else if (storage === StorageType.SESSION) {
          sessionStorage.setItem(TOKEN_STORAGE_KEY, token)
        }
      },
      removeToken(): void {
        localStorage.removeItem(TOKEN_STORAGE_KEY)
        sessionStorage.removeItem(TOKEN_STORAGE_KEY)
        OpenAPI.TOKEN = undefined
      },
      LoginStart(): void {
        this.authenticating = true
        this.authenticated = false
        this.error = false
      },
      async initializePubSub(): Promise<void> {
        const url = await getResponseBody(AccountService.accountApiPubsubUrlRetrieve())
        pubsubSubscriber.connect(url)
      },
      async LoginSuccess(): Promise<void> {
        getStorageWrapper().setItem("lo", "1")
        const profile = await getResponseBody(AccountService.accountApiProfileRetrieve())
        window.dataLayer?.push({ email: profile.email }) //TODO: nicer way

        this.authenticating = false
        this.authenticated = true
        this.error = false
      },
      LoginError(): void {
        this.authenticating = false
        this.authenticated = false
        this.error = true
      },
      Logout(): void {
        this.authenticating = false
        this.authenticated = false
        this.error = false
        pubsubSubscriber.close()
      },
      setBase(): void {
        if (getStorageWrapper().getItem("lo")) {
          this.firstLogin = false
        }
      },
      clearOldVersionStorage(): void {
        const storedPlatformVersion = localStorage.getItem(PLATFORM_VERSION_KEY)
        if (storedPlatformVersion !== PLATFORM_VERSION) {
          localStorage.clear()
          sessionStorage.clear()
        }

        localStorage.setItem(PLATFORM_VERSION_KEY, PLATFORM_VERSION)
      },
      async login(login: string, password: string, storage: StorageType) {
        this.LoginStart()
        try {
          const response = await getResponseBody(
            AccountService.accountLoginCreate({
              email: login,
              password: password,
            })
          )
          this.setToken(response.key, storage)
          this.initializePubSub()
        } catch (error) {
          this.LoginError()
          this.removeToken()
          throw error // TODO może opakować
        }
        this.LoginSuccess()
        return this.callback()
      },
      async logout() {
        try {
          await AccountService.accountLogoutCreate()
        } finally {
          this.removeToken()
          this.Logout()
          // eslint-disable-next-line no-unsafe-finally
          return this.callback()
        }
      },
      async sendPasswordResetLink(email: string) {
        return getResponseBody(AccountService.accountPasswordResetCreate({ email: email }))
      },
      async resetPassword(password: string, uid: string, token: string) {
        return getResponseBody(
          AccountService.accountPasswordResetConfirmCreate({
            new_password1: password,
            new_password2: password,
            uid: uid,
            token: token,
          })
        )
      },
      async changePassword(oldPassword: string, password: string) {
        return getResponseBody(
          AccountService.accountPasswordChangeCreate({
            old_password: oldPassword,
            new_password1: password,
            new_password2: password,
          })
        )
      },
      async register(data: UserRegisterDataRequest) {
        return getResponseBody(AccountService.accountRegistrationCreate(data))
      },
      async verifyRegistration(key: string) {
        return getResponseBody(
          AccountService.accountRegistrationVerifyEmailCreate({
            key: key,
          })
        )
      },
    },
  })
}

export const useAuthStore = createAuthStore(() => window.location.reload())
